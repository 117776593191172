import React from 'react'
import HeroImage from '../assets/ParkingHero.png'
import TopView from '../assets/ParkingTop.png'
import Components from '../assets/ParkingDiagram.png'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const ParkingSystem = () => {
    const codeString = `
    //code for disp borrowed from https://arduinogetstarted.com/tutorials/arduino-oled

    #include <Adafruit_NeoPixel.h>
    #include <SPI.h>
    #include <Wire.h>
    #include <Adafruit_GFX.h>
    #include <Adafruit_SSD1306.h>

    //General variable definition:
    int data []= {0,0,0,0};

    //Ultrasonic pin variable definition:
    int trigger [] = {48,40,26,34};
    int echo [] = {50,42,24,32};
    float duration [] = {0,0,0,0};
    float distance [] = {0,0,0,0};

    //LED variable definition:
    int led_count = 36;
    int led = 2; // pinout for led data pin

    Adafruit_NeoPixel strip(led_count, led, NEO_GRB + NEO_KHZ800); //sets up led strip

    int intensity = 255;
    uint32_t color = strip.Color(255,0,0); //red

    int index_one [] = {0,5,6,11,17,22,23,28}; //defining start and end of each parking spot (each spot 6 led wide)

    //Display variable definition:
    #define SCREEN_WIDTH 128 // OLED display width, in pixels
    #define SCREEN_HEIGHT 32 // OLED display height, in pixels
    #define OLED_RESET     -1 // Reset pin # (or -1 if sharing Arduino reset pin)
    #define SCREEN_ADDRESS 0x3C ///< See datasheet for Address; 0x3D for 128x64, 0x3C for 128x32
    Adafruit_SSD1306 display(SCREEN_WIDTH, SCREEN_HEIGHT, &Wire, OLED_RESET);

    void setup() {
    //General setup:
    Serial.begin(9600);

    //Ultrasonic setup:
    pinMode(trigger[0], OUTPUT); //defining the Trigger pin to be an output
    pinMode(trigger[1], OUTPUT);
    pinMode(trigger[2], OUTPUT);
    pinMode(trigger[3], OUTPUT);
    pinMode(echo[0], INPUT); //defining the Echo pin to be an input
    pinMode(echo[1], INPUT);
    pinMode(echo[2], INPUT);
    pinMode(echo[3], INPUT);

    //LED setup:
    strip.begin();
    strip.show();
    strip.setBrightness(intensity);

    //Display setup:
    if(!display.begin(SSD1306_SWITCHCAPVCC, SCREEN_ADDRESS)) {
        Serial.println(F("SSD1306 allocation failed"));
        for(;;); // Don't proceed
    }

    display.clearDisplay();
    display.setTextColor(WHITE);
    
    }

    void loop() {
    ultrasonicLoop(); //Checks for vehicle presence
    lightsLoop();  // Changes Lights for spots
    serialLoop(); // Notifies "server"
    displayLoop(); //Displays information on LCD
    }

    void ultrasonicLoop(){ //intake (control pins) and output (spots open/closed)

    for (int i=0; i<=sizeof(trigger); i++){
        digitalWrite(trigger[i], HIGH);
        delayMicroseconds(10);
        digitalWrite(trigger[i], LOW);

        duration[i] = pulseIn(echo[i], HIGH); //Future change to only exist locally
        distance[i] = 0.017 * duration[i]; // Calculated distance in cm

        if (distance[i] < 10){
        data[i] = 1;
        } else {
        data[i] = 0;
        }
    }    
    }

    void lightsLoop(){ //intake (spots open/close, control pins) and turn on / off that section of lights
    strip.clear();
    strip.show();

    int k = 0;

    for(int i=0; i<=sizeof(data); i++){
        if(data[i]){
        for(int j=index_one[k]; j<index_one[k+1]; j++) { // looks at first two spots in index array
            strip.setPixelColor(j, color); // Set pixel 'j' to value 'color'
        }
        strip.show();
        }
        k = k + 2; // when 'i' goes up from 0 to 1, we want to look at index spots 3 and 4, and so on as master loop proceeds
    }
    }

    void serialLoop(){ //Send data to serial monitor
    Serial.print("\\n");
    for(int i=0; i<=sizeof(data); i++){
        Serial.print(data[i]);
    }
    }

    void displayLoop(){ //Display spots open in each row
    display.clearDisplay();
    display.setTextSize(1);
    display.setCursor(0,0);

    display.println("Open Spots");
    display.print("Row 1: ");
    display.println((!data[0] + !data[1]));
    display.print("Row 2: ");
    display.println((!data[2] + !data[3]));
    display.display();
    delay(500);
    }


     `

  return (
    <div name='parkingsystem' className='w-full h-full bg-[#0a192f]'>
        
        {/* Container */}
        <div className='max-w-[1000px] mx-auto px-8 justify-center pt-24 text-gray-300 border-gray-300 shadow-lg shadow-[#040c16]'>
            <div className='text-center'>
                    <p className='text-2xl sm:text-4xl font-bold inline border-b-4 text-gray-300 border-gray-300'>Parking System [Class Project]</p>
            </div>
            <div className='pb-8 text-center'>
                <p className='text-xl sm:text-2xl pt-4 '>A small scale prototype to show functionality of an augmented parking experience</p>
            </div>

            <div style={{width: '60%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} className=' pb-8'>
                <img src={HeroImage} alt="HeroImage" />
            </div>

            <div className='text-center'>
                <p className='text-xl sm:text-2xl font-bold inline border-b-4 text-gray-300 border-gray-300'>Objective</p>
            </div>
            <div className=''>
                <p className='sm:text-xl pt-4 pb-8'>The objective was to enhance the parking experience on campuses, 
                addressing the common challenges associated with finding suitable parking spaces. 
                These challenges include determining the availability of parking spaces, assessing the occupancy levels of parking lots, 
                and identifying optimal parking locations. The aim of the project was to develop a prototype capable 
                of addressing these issues and demonstrating scalability for broader implementation.</p>
            </div>

            <div className='text-center '>
                <p className='text-xl sm:text-2xl font-bold inline border-b-4 text-gray-300 border-gray-300'>Solution</p>
            </div>
            <div className=''>
                <p className='sm:text-xl pt-4 pb-8'>The selected solution involved the installation of sensors in each parking spot to detect vehicle presence, 
                with an ultrasonic sensor chosen for this purpose. When a parking spot becomes vacant, 
                a light strip positioned in front of the spot illuminates green to indicate availability. 
                Simultaneously, a signal is relayed to a microcontroller, which tracks open spots and updates an LCD display located at the parking structure entrance,
                 providing patrons with real-time information on available spots and their general locations. 
                 Moreover, the microcontroller communicates with a server to update a web application, 
                 offering patrons online access to the same parking availability information, with potential future integration into a dedicated mobile application.
                 </p>
            </div>

            <div className='text-center pb-4'>
                <p className='text-xl sm:text-2xl font-bold inline border-b-4 text-gray-300 border-gray-300'>Components Overview</p>
            </div>
            <div style={{width: '90%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} className=''>
                <img src={Components} alt="Diagram"/>
            </div>
            <div style={{width: '90%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} className=''>
                <img src={TopView} alt="TopView"/>
            </div>
            <div className=''>
                <p className='text-xl sm:text-2xl pt-4'>Components</p>
            </div>
            <div className='pb-8'>
                <p className='sm:text-xl pt-4'>The prototype comprised several key components, including an Arduino Mega microcontroller, 
                four ultrasonic sensors, an LCD display, and a light strip. 
                The microcontroller was tasked with processing sensor data, transmitting relevant information to the LCD display, 
                and activating the LEDs in front of each ultrasonic sensor to display green when a spot is available. 
                Additionally, the microcontroller was programmed to relay data to a connected computer via serial communication, 
                functioning as the mock web server.
                </p>
            </div>

            <div className=''>
                <p className='text-xl sm:text-2xl pt-4'>The Code</p>
            </div>
            <div className='pb-8'>
                <p className='sm:text-xl pt-4'>The code was written in C and was uploaded to the Arduino microcontroller using the Arduino IDE.
                </p>
            </div>
            <div className='pb-8 overflow-hidden rounded-md'>
                <SyntaxHighlighter language="cpp" style={atomOneDark}>
                    {codeString}
                </SyntaxHighlighter>
            </div>
            <div className='pb-24'></div>
        </div>
    </div>
  )
}

export default ParkingSystem