import React from 'react'
import HeroImage from '../assets/ThorIso.png'
import ComponentCallout from '../assets/ThorFront.png'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Thor = () => {
    const codeString = `
    
     `

  return (
    <div name='thor' className='w-full h-full bg-[#0a192f]'>
        
        {/* Container */}
        <div className='max-w-[1000px] mx-auto px-8 justify-center pt-24 text-gray-300 border-gray-300 shadow-lg shadow-[#040c16]'>
            <div className='text-center'>
                    <p className='text-2xl sm:text-4xl font-bold inline border-b-4 text-gray-300 border-gray-300'>6-axis Robotic Arm [Thor]</p>
            </div>
            <div className='pb-8 text-center'>
                <a href="http://thor.angel-lm.com/" target="_blank" className='text-xl sm:text-2xl pt-4 '>An open source project to create low cost robotic arms</a>
            </div>

            <div style={{width: '60%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} className=' pb-8'>
                <img src={HeroImage} alt="HeroImage" />
            </div>

            <div className='text-center'>
                <p className='text-xl sm:text-2xl font-bold inline border-b-4 text-gray-300 border-gray-300'>Objective</p>
            </div>
            <div className=''>
                <p className='sm:text-xl pt-4 pb-8'>
                The aim of this project is to construct a 6-axis robotic arm, gaining proficiency in the associated kinematics principles, 
                and programming the arm to execute tasks while being controllable through various methods. 
                After completion of the arm's basic functionality, 
                the intention is to further develope the code base and contribute to the <a href="http://thor.angel-lm.com/" target="_blank">open-source project.</a></p>
            </div>

            <div className='text-center pb-4'>
                <p className='text-xl sm:text-2xl font-bold inline border-b-4 text-gray-300 border-gray-300'>Components Overview</p>
            </div>
            <div style={{width: '90%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} className=''>
                <img src={ComponentCallout} alt="Components"/>
            </div>
            <div className=''>
                <p className='text-xl sm:text-2xl pt-4'>Components</p>
            </div>
            <div className='pb-8'>
                <p className='sm:text-xl pt-4'>
                    The arm is 3D printed and uses off the shelf components such as stepper motors, optical sensors, and an Arduino Mega.
                    The full contruction guide can be viewed below hosted by Angel LM. <a href="http://thor.angel-lm.com/" target="_blank">The full open source project can be found at http://thor.angel-lm.com.</a>
                </p>
            </div>

            <iframe src="https://angellm.github.io/ThorAssembly/" style={{width: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} className='h-[700px]'></iframe>

            <div className='pb-24'></div>
        </div>
    </div>
  )
}

export default Thor