import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Work from "./components/Work";
import SparroPage from "./components/SparroPage";
import IgvcPage from "./components/IgvcPage";
import GazeboPage from "./components/GazeboPage";
import Swerve1Page from "./components/Swerve1Page";
import FrcPage from "./components/FrcPage";
import JoystickPage from "./components/JoystickPage";
import Resume from "./components/Resume";
import Thor from "./components/Thor";
import ParkingSystem from "./components/ParkingSystem";


import {Route, Switch, withRouter, Link, Routes} from 'react-router-dom';

const detailComponents = {
  about: <Route exact path='/about' component={About}> </Route>,
  skills: <Route exact path='/skills' component={Skills}> </Route>,
  sparro: <Route exact path='/sparro' component={SparroPage}> </Route>,
  igvc: <Route exact path='/igvc' component={IgvcPage}> </Route>,
  gazebo: <Route exact path='/gazebo' component={GazeboPage}> </Route>,
  swerve1: <Route exact path='/swerve1' component={Swerve1Page}> </Route>,
  frc: <Route exact path='/frc' component={FrcPage}> </Route>,
  joystick: <Route exact path='/joystick' component={JoystickPage}> </Route>,
  resume: <Route exact path='/resume' component={Resume}> </Route>,
}
/*
const renderDetailComponents = () => {
  if (history.action.toString() === 'PUSH') {
    return detailComponents[props.activePage]
  } else if (history.action.toString() === 'POP') {
    const activeComponent = history.location.pathname.slice(1)
    return detailComponents[activeComponent]
  }
}
*/

function App() {
  return (
    <div>
      <Navbar />
        <Routes>
          <Route exact path='' element={<Work />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/skills' element={<Skills />}></Route>
          <Route path='/sparro' element={<SparroPage />}></Route>
          <Route path='/igvc' element={<IgvcPage />}></Route>
          <Route path='/gazebo' element={<GazeboPage />}></Route>
          <Route path='/swerve1' element={<Swerve1Page />}></Route>
          <Route path='/frc' element={<FrcPage />}></Route>
          <Route path='/joystick' element={<JoystickPage />}></Route>
          <Route path='/resume' element={<Resume />}></Route>
          <Route path='/thor' element={<Thor />}></Route>
          <Route path='/parkingsystem' element={<ParkingSystem />}></Route>
        </Routes>
    </div>
  );
}

export default App;
